<template>
  <div id="site-list">
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.name) }}</span>
        </template>

        <!-- distributor -->
        <template #[`item.distributor`]="{ item }">
          <span class="text-no-wrap">{{ item.distributor?.business_name }}</span>
        </template>

        <!-- parent -->
        <template #[`item.parent`]="{ item }">
          <span class="text-no-wrap">{{ item.parent?.key }}</span>
        </template>

        <!-- value -->
        <template #[`item.value`]="{ item }">
          <span class="text-wrap">{{ t(item.value) }}</span>
        </template>

        <!-- instance -->
        <template #[`item.instance`]="{ item }">
          <!-- <span class="text-wrap">{{ item.instance.thumbnail }}</span> -->

          <div class="mt-4 mb-4">
            <img
              :src="item.instance.thumbnail"
              alt="Thumbnail"
              class="thumbnail-image"
            >
          </div>

          <div
            v-for="child in item.children"
            :key="child"
            class="ml-8"
          >
            <img
              :src="child.instance.thumbnail"
              alt="Thumbnail"
              class="thumbnail-image"
            >
            ({{ child.distributor.slug }})
            <v-icon @click="setAside(child)">
              {{ icons.mdiPencilOutline }}
            </v-icon>

            <v-icon @click="setImageUploadAside(child)">
              {{ icons.mdiImage }}
            </v-icon>
          </div>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="setImageUploadAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Replace Image</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="setAsideForAddChild(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Add Child Asset (Distributor alternate)</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteSiteAssets(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <site-assets-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      :child-obj="childObj"
      @changed="loadData(); aside = false"
    />

    <site-assets-upload-image-aside
      v-if="imageUploadAside"
      v-model="imageUploadAside"
      :resource="resource"
      @changed="loadData(); imageUploadAside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiDeleteOutline,
mdiDotsVertical,
mdiImage,

mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import SiteAssetsAside from './site-assets-resource/SiteAssetsAside.vue'
import SiteAssetsUploadImageAside from './site-assets-resource/SiteAssetsUploadImageAside.vue'
import useSiteAssets from './useSiteAssets'

export default {
  components: {
    ImportDialog,
    SiteAssetsAside,
    SiteAssetsUploadImageAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteSiteAssets,
    } = useSiteAssets()

    const actions = ['Edit']

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
      childObj.value = null
    }
    const setAsideForAddChild = item => {
      aside.value = true
      childObj.value = item
      resource.value = null
    }
    const resource = ref(null)
    const childObj = ref(null)

    const imageUploadAside = ref(false)
    const setImageUploadAside = item => {
      imageUploadAside.value = true
      resource.value = item
    }

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiPencilOutline,
        mdiDotsVertical,
        mdiImage,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAsideForAddChild,
      setAside,
      aside,
      resource,
      childObj,

      imageUploadAside,
      setImageUploadAside,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadData,
      deleteSiteAssets,
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.thumbnail-image {
  max-width: 100px; /* Adjust the width as needed */
  height: auto;
  /* Add additional styles if needed, e.g. padding or margin */
}
</style>
