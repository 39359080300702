<template>
  <v-form ref="formElem">
    <div class="my-5">
      Replace the image
    </div>
    <v-card color="#DDD">
      <v-img
        class="mx-auto"
        :width="300"
        :height="300"
        :src="resource.instance.detail"
      ></v-img>
    </v-card>
    <v-card>
      <v-card-text>
        <v-file-input
          v-model="form.file"
          :loading="loading"
          accept="image/*"
          label="Choose Image for Logo"
          :rules="[required]"
        >
        </v-file-input>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSites from '../../useSites'

export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    childObj: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      sitesList,
      loadSites,
      sitePagesList,
      loadSitePages,
      siteSectionsList,
      loadSiteSections,
      siteItemsList,
      loadSiteItems,
      siteItemRulesList,
      loadSiteItemRules,
      loadDistributors,
      distributorList,
      loadSiteAssets,
      siteAssetsList,
    } = useSites()
    loadSites()
    loadDistributors()
    loadSiteAssets()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('file', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,

      distributorList,
      siteAssetsList,
      sitesList,
    }
  },
}
</script>
