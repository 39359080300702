import { destroySiteAsset, fetchSiteAssets } from '@/api/site/site'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Key',
      value: 'key',
    },
    {
      text: 'Instance Thumbnail',
      value: 'instance',
    },
    {
      text: 'Child Count',
      value: 'child_count',
    },

    // {
    //   text: 'Distributor',
    //   value: 'distributor',
    // },
    // {
    //   text: 'Parent',
    //   value: 'parent',
    // },
    // {
    //   text: 'Instance ID',
    //   value: 'instance_id',
    // },
    // {
    //   text: 'Instance Type',
    //   value: 'instance_type',
    // },
    {
      text: 'Reserved',
      value: 'reserved',
    },
    {
      text: 'Notes',
      value: 'notes',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    name: '',
    root_only: true,
  })

  const loadData = async () => {
    loading.value = true

    try {
      const { data } = await fetchSiteAssets(useTableOptions(options.value, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  const deleteSiteAssets = obj =>
    destroySiteAsset(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadData()
  })

  watch(
    () => filters.name,
    debounce(() => {
      loadData()
    }, 300),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteSiteAssets,
  }
}
