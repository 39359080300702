<template>
  <v-navigation-drawer :value="active" temporary touchless :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'" app @input="(val) => $emit('aside', val)">
    <site-assets-upload-image-form :resource="resource" :loading="loading" :errors="errors" @submit="handleSubmit" />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { updateSiteImageAsset } from '@api/site/site'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SiteAssetsUploadImageForm from './SiteAssetsUploadImageForm.vue'

export default {
  components: {
    SiteAssetsUploadImageForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      for (const key in form) {
        if (form[key] === null) {
          delete form[key]
        }
      }

      const data = new FormData()
      Object.keys(form).forEach(key => {
        data.append(key, form[key])
      })
      const request = updateSiteImageAsset(props.resource.id, data)
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
