<template>
  <v-navigation-drawer :value="active" temporary touchless :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'" app @input="(val) => $emit('aside', val)">
    <site-assets-form :resource="resource" :loading="loading" :errors="errors" @submit="handleSubmit" :childObj="childObj" />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeSiteAsset, updateSiteAsset } from '@api/site/site'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SiteAssetsForm from './SiteAssetsForm.vue'

export default {
  components: {
    SiteAssetsForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
    childObj: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      for (const key in form) {
        if (form[key] === null) {
          delete form[key]
        }
      }

      const data = new FormData()
      Object.keys(form).forEach(key => {
        data.append(key, form[key])
      })

      const request = props.resource ? updateSiteAsset(props.resource.id, { ...form }) : storeSiteAsset(data)
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
