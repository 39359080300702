<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.notes"
          label="*Notes"
          outlined
          class="mt-5"
          hint="Make notes on how you intend to use it. e.g. Home carousel image #1"
          :rules="[required]"
        />

        <p v-if="resource">
          You can only "replace image" once you created it.
        </p>

        <div v-if="!resource && !hasParent">
          <v-switch
            v-model="hasParent"
            hide-details
            class="mt-0 mx-2"
          ></v-switch>
          <span>Make it for distributor alternative.</span>
        </div>

        <template v-else>
          <v-select
            v-model="form.parent_id"
            :disabled="!!resource"
            :items="siteAssetsList"
            item-text="key"
            item-value="id"
            label="Select Parent Key"
            outlined
            hide-details
            class="mt-5"
          ></v-select>

          <v-autocomplete
            v-model="form.distributor_id"
            :disabled="!!resource"
            :items="distributorList"
            item-text="business_name"
            item-value="id"
            label="Select Distributor"
            outlined
            hide-details
            class="mt-5"
          ></v-autocomplete>
        </template>

        <!-- <v-text-field v-model="form.key" label="Key" outlined :rules="[required]" class="mt-5" /> -->

        <v-file-input
          v-if="!resource"
          v-model="form.file"
          :loading="loading"
          accept="image/*"
          label="Choose Image for Logo"
          :rules="[required]"
        >
        </v-file-input>

        <template v-if="resource">
          <div class="mt-2">
            Detail:
          </div>
          <div>{{ resource.instance.detail }}</div>

          <div class="mt-2">
            Thumbnail:
          </div>
          <div>{{ resource.instance.thumbnail }}</div>
        </template>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation';
import { } from '@mdi/js';
import { ref } from '@vue/composition-api';
import useSites from '../../useSites';

export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    childObj: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      sitesList,
      loadSites,
      loadDistributors,
      distributorList,
      loadSiteAssets,
      siteAssetsList,
    } = useSites()
    loadSites()
    loadDistributors()
    loadSiteAssets()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      parent_id: props.childObj ? props.childObj.id : props.resource ? props.resource.parent_id : null,
      distributor_id: props.childObj ? props.childObj.distributor_id : props.resource ? props.resource.distributor_id : null,

      ...field('notes', null),
      ...field('file', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const hasParent = ref(!!props.resource?.parent_id)

    return {
      form,
      formElem,
      validate,
      required,

      hasParent,
      distributorList,
      siteAssetsList,
      sitesList,
    }
  },
}
</script>
