var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "*Notes",
      "outlined": "",
      "hint": "Make notes on how you intend to use it. e.g. Home carousel image #1",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  }), _vm.resource ? _c('p', [_vm._v(" You can only \"replace image\" once you created it. ")]) : _vm._e(), !_vm.resource && !_vm.hasParent ? _c('div', [_c('v-switch', {
    staticClass: "mt-0 mx-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.hasParent,
      callback: function callback($$v) {
        _vm.hasParent = $$v;
      },
      expression: "hasParent"
    }
  }), _c('span', [_vm._v("Make it for distributor alternative.")])], 1) : [_c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "disabled": !!_vm.resource,
      "items": _vm.siteAssetsList,
      "item-text": "key",
      "item-value": "id",
      "label": "Select Parent Key",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parent_id", $$v);
      },
      expression: "form.parent_id"
    }
  }), _c('v-autocomplete', {
    staticClass: "mt-5",
    attrs: {
      "disabled": !!_vm.resource,
      "items": _vm.distributorList,
      "item-text": "business_name",
      "item-value": "id",
      "label": "Select Distributor",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  })], !_vm.resource ? _c('v-file-input', {
    attrs: {
      "loading": _vm.loading,
      "accept": "image/*",
      "label": "Choose Image for Logo",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  }) : _vm._e(), _vm.resource ? [_c('div', {
    staticClass: "mt-2"
  }, [_vm._v(" Detail: ")]), _c('div', [_vm._v(_vm._s(_vm.resource.instance.detail))]), _c('div', {
    staticClass: "mt-2"
  }, [_vm._v(" Thumbnail: ")]), _c('div', [_vm._v(_vm._s(_vm.resource.instance.thumbnail))])] : _vm._e()], 2), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }